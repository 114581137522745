import React from 'react'
import { Reservation } from '@/features/checkIn/search-reservations-slice'
import { useTranslation } from 'react-i18next'
import { Card } from '../atoms/card'
import { css } from '@emotion/react'
import { Divider } from '../atoms/divider'
import { ListItem } from '../atoms/list-item'
import { LanguageType } from '@/i18n'
import { groupBy } from 'lodash'
import { textOverflowStyle } from '@/styles/common'
import { usePickByLanguage } from '@/hooks/use-pick-by-language'

type ReservationSelectType = Pick<
  Reservation,
  | 'guestName'
  | 'guestNameEn'
  | 'id'
  | 'nights'
  | 'paxTotal'
  | 'plan'
  | 'planEn'
  | 'reservationId'
  | 'userName'
  | 'userNameEn'
  | 'reservationRoom'
  | 'roomNumber'
>

interface ReservationSelectCardProps {
  reservation: ReservationSelectType
  selectLabel: React.ReactNode
}

export const ReservationSelectCard: React.FC<ReservationSelectCardProps> = ({ reservation, selectLabel }) => {
  const { t } = useTranslation()
  const pickByLanguage = usePickByLanguage()

  const computedRoomInfo = (reservationRoom): Array<{ roomNum: number; roomName: Record<LanguageType, string> }> => {
    const roomsGroupByType: Record<string, any[]> = groupBy(reservationRoom || {}, ({ roomTypeId }) => roomTypeId)
    return Object.values(roomsGroupByType).flatMap(groupByRooms => ({
      roomNum: groupByRooms.length,
      roomName: {
        [LanguageType.ja]: groupByRooms[0].typeNameJa || '',
        [LanguageType.en]: groupByRooms[0].typeNameEn || groupByRooms[0].typeNameJa,
        [LanguageType.zh]: groupByRooms[0].typeNameEn || groupByRooms[0].typeNameJa,
        [LanguageType.ko]: groupByRooms[0].typeNameEn || groupByRooms[0].typeNameJa,
        [LanguageType.zhTw]: groupByRooms[0].typeNameEn || groupByRooms[0].typeNameJa,
      },
    }))
  }

  return (
    <Card cardCss={cardStyle} key={reservation.id}>
      {selectLabel}
      <Divider customCss={{ marginLeft: '-1.5rem', marginRight: '-1.5rem', marginTop: '1rem' }} />
      <div css={[listItemBlockStyle]}>
        <ListItem
          title={t('type of room')}
          text={pickByLanguage({
            [LanguageType.ja]: reservation.plan,
            [LanguageType.en]: reservation.planEn,
            [LanguageType.ko]: reservation.planEn,
            [LanguageType.zh]: reservation.planEn,
            [LanguageType.zhTw]: reservation.planEn,
          })}
        />

        <ListItem
          title={t('Room')}
          text={computedRoomInfo(reservation.reservationRoom)
            .map(({ roomName, roomNum }) => `${pickByLanguage(roomName)} / ${roomNum}${roomNum > 1 ? t('Rooms') : t('Room')}`)
            .join('\n')}
        />
        <ListItem
          title={t('Number of persons')}
          text={reservation.paxTotal && `${reservation.paxTotal}${reservation.paxTotal === 1 ? t('Guest') : t('Guests')}`}
        />
        <ListItem title={t('reservation holder')} text={reservation.userName || reservation.userNameEn} />
        <ListItem title={t('confirmed guest')} text={reservation.guestName || reservation.guestNameEn} />
      </div>
      <Divider customCss={{ marginLeft: '-1.5rem', marginRight: '-1.5rem', marginBottom: '1rem' }} />
      <p css={reservationItemStyle}>
        <span>{t('Reservation ID')}</span>
        <span css={textOverflowStyle}>{reservation.reservationId}</span>
      </p>
    </Card>
  )
}

const cardStyle = css({
  paddingTop: '1rem',
  paddingBottom: '1rem',
})

const reservationItemStyle = css({
  display: 'flex',
  fontSize: '12px',
  lineHeight: 1.5,
  span: {
    '&:first-of-type': {
      flex: '0 0 33%',
      marginRight: '2%',
    },
    '&:last-of-type': {
      flex: '0 0 65%',
    },
  },
})

const listItemBlockStyle = css({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  paddingTop: '1.5rem',
  paddingBottom: '1.5rem',
})
