import React from 'react'
import { Outlet, RouterProvider, createBrowserRouter, redirect } from 'react-router-dom'
import { CheckIn } from '../pages/checkIn'
import { ReservationConfirm } from '../pages/checkIn/reservation-confirm'
import { ReservationSearch } from '../pages/checkIn/reservation-search'
import { RegisterAccount } from '../pages/checkIn/register-account'
import { CustomCheckIn } from '../pages/checkIn/custom-check-in'
import { CheckInPayment } from '../pages/checkIn/check-in-payment'
import { CheckInConfirm } from '../pages/checkIn/check-in-confirm'
import { CheckInEdit } from '../pages/checkIn/check-in-edit'
import { CheckInAccept } from '../pages/checkIn/check-in-accept'
import { Login } from '../pages/login'
import { EmailLogin } from '../pages/login/email-login'
import { CheckoutSearch } from '../pages/checkout/checkout-search'
import { CheckoutReservationList } from '../pages/checkout/checkout-reservation-list'
import { CheckoutReview } from '../pages/checkout/checkout-review'
import { CheckoutThanks } from '../pages/checkout/checkout-thanks'
import { CheckoutPayment } from '../pages/checkout/checkout-payment'
import { Home } from '../pages'
import { AssetContent } from '../pages/assetContents'
import { MyPage } from '../pages/mypage'
import { CheckInHistory } from '../pages/mypage/check-in-history-list'
import { CheckInHistoryInfo } from '../pages/mypage/check-in-history-info'
import { MemberInfo } from '../pages/mypage/member-info'
import { CreditCard } from '../pages/mypage/credit-card'
import { RegisterCard } from '../pages/mypage/register-card'
import { Invoice } from '../pages/mypage/invoice'
import { InvoiceInfo } from '../pages/mypage/invoice-info'
import { TermsOfUse } from '../pages/termsOfUse/terms-of-use'
import { PrivacyPolicy } from '../pages/privacyPolicty/privacy-policy'
import { SecurityPolicy } from '../pages/securityPolicy/security-policy'
import { ErrorBoundary } from '@/app/error-boundary'
import { Error404 } from '../pages/404'
import { ForgotPassword } from '../pages/login/forgot-password'
import { AuthCallback } from '../pages/login/auth-callback'
import { store } from '@/app/store'
import { checkUserDataValid, getAccountInfo } from '@/features/user/account-slice'
import { isAxiosError } from 'axios'
import { isEmpty } from 'lodash'
import { AroundSpotList } from '../pages/aroundSpot/around-spot-list'
import { AroundSpotDetail } from '../pages/aroundSpot/around-spot-detail'
import { NoticeList } from '../pages/notice/notice-list'
import { NoticeDetail } from '../pages/notice/notice-detail'
import { RequestList } from '../pages/request/request-list'
import { RequestConfirmation } from '../pages/request/request-confirmation'
import { RequestOrderConfirmation } from '../pages/request/request-order-confirmation'
import { RequestThanks } from '../pages/request/request-thanks'
import { SpaceList } from '../pages/space/space-list'
import { SpaceDetail } from '../pages/space/space-detail'
import { SpaceConfirmation } from '../pages/space/space-application-confirmation'
import { SpaceThanks } from '../pages/space/space-thanks'
import { SpaceReservationSearch } from '../pages/space/space-reservation-search'
import { SpaceReservationSelect } from '../pages/space/space-reservation-select'
import { MembershipAccount } from '../pages/space/membership-account'

export const Router: React.FC = () => {
  const storageKeyForHotelId = 'app.hotel.id'

  const storeHotelId = (request: Request) => {
    const url = new URL(request.url)
    const paramHotelId = url.searchParams.get('hotelId')
    if (paramHotelId) {
      localStorage.setItem(storageKeyForHotelId, paramHotelId)
    }
  }
  const storeRedirectFromUrl = (url: URL) => {
    const queryString = url.searchParams.toString() ? `?${url.searchParams.toString()}` : ''
    sessionStorage.setItem('redirectFrom', `${url.pathname}${queryString}`)
  }

  const redirectLogin = (request: Request, params?: string) => {
    const url = new URL(request.url)
    const auth = localStorage.getItem('access_token')
    if (auth) {
      return null
    }
    storeRedirectFromUrl(url)
    return redirect(params ? `/login?${params}` : '/login')
  }

  const redirectWhenNonHotelId = (request: Request) => {
    const url = new URL(request.url)
    const storedHotelId = localStorage.getItem(storageKeyForHotelId) || ''
    if (!storedHotelId) {
      return redirect('/404')
    }
    if (!url.searchParams.has('hotelId')) {
      url.searchParams.set('hotelId', storedHotelId)
      return redirect(`${url.pathname}?${url.searchParams.toString()}`)
    }
    return null
  }

  const loadUserData = async (request: Request) => {
    const userData = await store.dispatch(getAccountInfo())
    if (isAxiosError(userData.payload)) {
      if (userData.payload.response?.status === 401) {
        const url = new URL(request.url)
        storeRedirectFromUrl(url)
        return redirect('/login')
      }
    } else {
      return null
    }
  }

  const beforeEachLoader = (opt?: { authRequired?: boolean; prepareUserData?: boolean }) => {
    return async ({ request }: { request: Request }) => {
      storeHotelId(request)

      const loginResponse = opt?.authRequired && redirectLogin(request)
      if (loginResponse) {
        return loginResponse
      }

      const userData = store.getState().accountInfo.user
      if (isEmpty(userData) && opt?.authRequired) {
        const userDataResponse = await loadUserData(request)
        if (userDataResponse) {
          return userDataResponse
        }
      }

      if (!opt?.prepareUserData && opt?.authRequired && !isEmpty(userData) && !checkUserDataValid(userData)) {
        const url = new URL(request.url)
        storeRedirectFromUrl(url)
        return redirect('/register-account')
      }

      return redirectWhenNonHotelId(request)
    }
  }

  const router = createBrowserRouter([
    {
      element: (
        <ErrorBoundary>
          <Outlet />
        </ErrorBoundary>
      ),
      children: [
        {
          path: '/',
          element: <Home />,
          loader: beforeEachLoader(),
          handle: { smallFooterMargin: true },
        },
        {
          path: '/auth-cb',
          element: <AuthCallback />,
        },
        {
          path: '/mypage',
          element: <MyPage />,
          loader: beforeEachLoader({ authRequired: true }),
        },
        {
          path: '/mypage/checkin-history',
          element: <CheckInHistory />,
          loader: beforeEachLoader({ authRequired: true }),
        },
        {
          path: '/mypage/checkin-history/:id',
          element: <CheckInHistoryInfo />,
          loader: beforeEachLoader({ authRequired: false }),
          handle: { smallFooterMargin: true },
        },
        {
          path: '/mypage/account',
          element: <MemberInfo />,
          loader: beforeEachLoader({ authRequired: true }),
          handle: { smallFooterMargin: true },
        },
        {
          path: '/mypage/credit-card',
          element: <CreditCard />,
          loader: beforeEachLoader({ authRequired: true }),
        },
        {
          path: '/mypage/credit-card/register',
          element: <RegisterCard />,
          loader: beforeEachLoader({ authRequired: true }),
        },
        {
          path: '/mypage/usage-detail',
          element: <Invoice />,
          loader: beforeEachLoader({ authRequired: true }),
        },
        {
          path: '/mypage/usage-detail/:orderId',
          element: <InvoiceInfo />,
          loader: beforeEachLoader({ authRequired: true }),
          handle: { smallFooterMargin: true },
        },
        {
          path: '/about-terms-of-use',
          element: <TermsOfUse />,
        },
        {
          path: '/about-privacy-policy',
          element: <PrivacyPolicy />,
        },
        {
          path: '/about-security-policy',
          element: <SecurityPolicy />,
        },
        {
          path: '/checkin',
          element: <CheckIn />,
          loader: beforeEachLoader(),
        },
        {
          path: '/checkin-reservation-confirm',
          element: <ReservationConfirm />,
          loader: beforeEachLoader(),
        },
        {
          path: '/checkin-reservation-search',
          element: <ReservationSearch />,
          loader: beforeEachLoader(),
        },
        {
          path: '/custom-checkin',
          element: <CustomCheckIn />,
          loader: beforeEachLoader({ authRequired: true }),
        },
        {
          path: '/checkin-payment',
          element: <CheckInPayment />,
          loader: beforeEachLoader({ authRequired: true }),
        },
        {
          path: '/checkin-confirm',
          element: <CheckInConfirm />,
          loader: beforeEachLoader({ authRequired: true }),
        },
        {
          path: '/checkin-edit',
          element: <CheckInEdit />,
          loader: beforeEachLoader({ authRequired: true }),
        },
        {
          path: '/checkin-accept',
          element: <CheckInAccept />,
          loader: beforeEachLoader({ authRequired: true }),
          handle: { smallFooterMargin: true },
        },
        {
          path: '/login',
          element: <Login />,
          loader: beforeEachLoader(),
        },
        {
          path: '/forget-password',
          element: <ForgotPassword />,
          loader: beforeEachLoader(),
        },
        {
          path: '/login-email',
          element: <EmailLogin />,
          loader: beforeEachLoader(),
        },
        {
          path: '/register-account',
          element: <RegisterAccount />,
          loader: beforeEachLoader({ authRequired: true, prepareUserData: true }),
        },
        {
          path: '/register-membership',
          element: <MembershipAccount />,
          loader: beforeEachLoader({ authRequired: true, prepareUserData: true }),
        },
        {
          path: '/checkout',
          element: <CheckoutSearch />,
          loader: beforeEachLoader(),
        },
        {
          path: '/checkout/select-reservation',
          element: <CheckoutReservationList />,
          loader: beforeEachLoader(),
        },
        {
          path: '/checkout/payment',
          element: <CheckoutPayment />,
          loader: beforeEachLoader(),
        },
        {
          path: '/checkout/review',
          element: <CheckoutReview />,
          loader: beforeEachLoader(),
        },
        {
          path: '/checkout/thanks',
          element: <CheckoutThanks />,
          loader: beforeEachLoader(),
        },
        {
          path: '/contents/:assetId/:contentId',
          element: <AssetContent />,
          loader: beforeEachLoader(),
          handle: { smallFooterMargin: true },
        },
        {
          path: '/around-spot/:assetId',
          element: <AroundSpotList />,
          loader: beforeEachLoader(),
          handle: { noHeaderShadow: true, smallFooterMargin: true },
        },
        {
          path: '/around-spot/:assetId/:detailId',
          element: <AroundSpotDetail />,
          loader: beforeEachLoader(),
          handle: { smallFooterMargin: true },
        },
        {
          path: '/notice/:assetId',
          element: <NoticeList />,
          loader: beforeEachLoader(),
          handle: { smallFooterMargin: true },
        },
        {
          path: '/notice/:assetId/:noticeId',
          element: <NoticeDetail />,
          loader: beforeEachLoader(),
          handle: { smallFooterMargin: true },
        },
        {
          path: '/request/:assetId',
          element: <RequestList />,
          loader: beforeEachLoader(),
          handle: { noHeaderShadow: true, smallFooterMargin: true },
        },
        {
          path: '/request/:assetId/confirmation',
          element: <RequestConfirmation />,
          loader: beforeEachLoader(),
        },
        {
          path: '/request/:assetId/order-confirmation',
          element: <RequestOrderConfirmation />,
          loader: beforeEachLoader(),
        },
        {
          path: '/request/thanks',
          element: <RequestThanks />,
          loader: beforeEachLoader(),
        },
        {
          path: '/space/:assetId',
          element: <SpaceList />,
          loader: beforeEachLoader(),
          handle: { smallFooterMargin: true },
        },
        {
          path: '/space/:assetId/:spaceId',
          element: <SpaceDetail />,
          loader: beforeEachLoader(),
        },
        {
          path: '/space/:spaceId/application-confirm',
          element: <SpaceConfirmation />,
          loader: beforeEachLoader(),
        },
        {
          path: '/space/:spaceId/application-confirm/thanks',
          element: <SpaceThanks />,
          loader: beforeEachLoader(),
        },
        {
          path: '/space/reservation-search',
          element: <SpaceReservationSearch />,
          loader: beforeEachLoader(),
        },
        {
          path: '/space/reservation-select',
          element: <SpaceReservationSelect />,
          loader: beforeEachLoader(),
        },
        {
          path: '*',
          element: <Error404 />,
        },
      ],
    },
  ])

  return <RouterProvider router={router} />
}
